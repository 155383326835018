import React, { useEffect, useState } from 'react'
import { auth, db } from '../Config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
// Styling Part 
import './Home.scss'
import { collection, deleteDoc, doc, getDocs, setDoc, updateDoc } from 'firebase/firestore';

// to generate random id 
const getRandomId = () => {
  return Math.random().toString(36).slice(2);
}

let inetialstate = {

  title: '',
  location: '',
  description: '',
  userId: '',
  dataId: ''
}

export default function Register() {


  // for screen representation 
  const [screen, setScreen] = useState(true)

  const [user, setUser] = useState({})

  const prop = user.uid;

  const [state, setState] = useState(inetialstate)

  // for buttons 

  const [processing, setProcessing] = useState(false)

  // for Firebase arrays 

  const [datas, setdatas] = useState([])

  // to show table 

  const [table, setTable] = useState(true)


  // console.log(state)

  // to render login 

  useEffect(() => {


    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        setScreen(false)

        // const uid = user.uid;

      } else {
        // User is signed out
        // ...
      }
    });

  }, [])


  // Data Enter krny ka process 


  const handleChange = (e) => {

    const { name, value } = e.target

    setState(s => ({ ...s, [name]: value }))

  }

  const handleSubmit = async (e) => {

    e.preventDefault()

    const { title, location, description } = state

    let todoData = {
      title, location, description,
      id: getRandomId(),
      dataId: prop
    }

    // Add a new document in collection "cities"

    setProcessing(true)

    try {
      await setDoc(doc(db, "todos", todoData.id), todoData);
      // console.log("Document written with ID: ", docRef.id);

      setState(inetialstate);
      alert("your task has been added")
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Something went wrong")
    }
    setProcessing(false)
    readDocuments()
  }

  // Read Data From FireBase 


  const readDocuments = async () => {

    setTable(false)

    const querySnapshot = await getDocs(collection(db, "todos"));

    let array = [];

    querySnapshot.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());

      const data = doc.data()

      array.push(data);
      // doc.data() is never undefined for query doc snapshots

    });
    setdatas(array)

    setTable(true)
  }



  useEffect(() => {

    readDocuments()


  }, [])

// Todo Delete krny k liye 


const handleDelete=async (todo)=>{

    try {
     
      await deleteDoc(doc(db, "todos",todo.id));
    
     alert("your task has been deleted")
   } catch (e) {
     console.error("Error deleting document: ", e);
     alert("Something went wrong")
   }
  
   readDocuments()
  }
  

// edit ko handle krny k liye 


const handleUpdate=async (todo)=>{
 
let todoEdit={}
todoEdit =todo
inetialstate = todoEdit
setState(inetialstate)

try {
     
  await deleteDoc(doc(db, "todos",todo.id));

} catch (e) {
 console.error("Error deleting document: ", e);
 alert("Something went wrong")
}

readDocuments()

}





  // agr screen false hy to data show hoga wrna ye 


  if (screen) {

    return <div className="container text-center">
      <div className="row">
        <div className="col">
          <h1>plz sign Up or log in</h1>
        </div>
      </div>
    </div>

  }


  return (
    <>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">

            <div className="form-card">
              <h2>Add Todo</h2>

              <form onSubmit={handleSubmit} >
                <label>Title</label>
                <input type="text" id="title" name="title" placeholder="Enter title" value={state.title} onChange={handleChange} />

                <label >Location</label>
                <input type="text" id="location" name="location" placeholder="Enter location" value={state.location} onChange={handleChange} />

                <label >Description</label>
                <textarea id="description" name="description" placeholder="Enter description" value={state.description} onChange={handleChange} ></textarea>

                <button type="submit" disabled={processing} >
                  add
                </button>
              </form>
            </div>


          </div>
          <div className="col-md-8">

            {
              table ? <div className="table-responsive">

                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Task#</th>
                      <th scope="col">Title</th>
                      <th scope="col">Location</th>
                      <th scope="col">Description</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {

                      datas.map((todo, i) => {

                        return <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{todo.title}</td>
                          <td>{todo.location}</td>
                          <td>{todo.description}</td>
                          <td>
                            <button className='btn btn-info btn-sm ' onClick={()=>{handleUpdate(todo)}} >Edit</button>
                            <button className='btn btn-danger btn-sm' onClick={()=>{handleDelete(todo)}}>Delete</button>
                          </td>
                        </tr>

                      })


                    }
                  </tbody>
                </table>
              </div> : <div className="text-center d-flex justify-content-center align-items-center">
              <div className="spinner-border text-info" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            }

          </div>
        </div>
      </div>

    </>
  )
















}

