import React from 'react'
import { Link } from 'react-router-dom'
import './Header.scss'
const Header = () => {
  return (
    <nav class="navbar navbar-expand-lg bg-primary navbar-dark bg-body-tertiary">
    <div class="container-fluid">
      <Link to='/' class="navbar-brand" >Navbar</Link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to='/'>Tasks</Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to='signein'>Signe In</Link>
          </li>
        </ul>
        <form class="d-flex" role="search">
         
        <Link  to='registration'><button class="btn nv-b btn-outline-success" type="submit">Signe Up </button></Link>
        </form>
      </div>
    </div>
  </nav>
  )
}

export default Header