import './App.scss';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
// components 

import Header from './Components/Header/Header';
import Navbar from './Components/Header/Navbar';
import Footer from './Components/Footer/Footer';
// Pages 

import Routes from './Pages/Routes'


function App() {
  return (
    <>

      <header>
        <Navbar />
        <Header />
      </header>

      <main className='d-flex align-items-center'>

 <Routes/>
      </main>

      <footer className='bg-primary'>
        <Footer />
      </footer>

    </>
  );
}

export default App;
