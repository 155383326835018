import React, { useState } from 'react'
import { auth } from '../Config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import './Registration.scss'

const inetialstate = {

    email: '',
    password: '',
    confirmPassword: ''

}


export default function Register() {

    const [state, setState] = useState(inetialstate);
    const [processing, setProcessing] = useState(false)


    const handleChange = (e) => {

        const { name, value } = e.target

        setState(s => ({ ...s, [name]: value }))

    }





    const handleSubmit = (e) => {

        e.preventDefault()

       
        let { email, password, confirmPassword } = state;

        if (password !== confirmPassword) {
            return alert("please confirm your password")
        }

        if (password.length < 6) {
            return alert('password should be atleaset 6 characters long')
        }

        console.log(email, password, confirmPassword)
        setProcessing(true)
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user)
                alert('user signup completed')
                setState(inetialstate)
                // ...
            })
            .catch((error) => {
                console.log(error)
                // const errorCode = error.code;
                // const errorMessage = error.message;
                alert('something went wrong')
                // ..
            }).finally(() => {
                setState(inetialstate)
                setProcessing(false)
            });



    }

    return (
        <div className="signup-card">
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit}>
                <label >Email</label>
                <input type="email" id="email" name="email" value={state.email} required onChange={handleChange} />
                <label >Password</label>
                <input type="password" id="password" name="password" value={state.password} required onChange={handleChange} />
                <label >Confirm Password</label>
                <input type="password" id="confirm-password" name="confirmPassword" value={state.confirmPassword} required onChange={handleChange} />
                <button type="submit" >{

                    !processing ? <span>Sign Up</span> : <div className="spinner spinner-glow spinner-sm"></div>

                }</button>
            </form>
        </div>

    )


}












