// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCiVmqccapyH8c7mtwLLXkgeeTRXBahv1s",
  authDomain: "my-todo-app-98b42.firebaseapp.com",
  projectId: "my-todo-app-98b42",
  storageBucket: "my-todo-app-98b42.appspot.com",
  messagingSenderId: "7623557596",
  appId: "1:7623557596:web:733cd59db2e9435472cf19",
  measurementId: "G-4YW0WBX2B4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export {auth,db}