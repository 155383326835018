import React, { useEffect, useState } from 'react'

// for time 
import dayjs from 'dayjs'
const Navbar = () => {

    const [currentTime,setCurrentTime]=useState('12')

    useEffect(()=>{
    
        setInterval(()=>{
            setCurrentTime(dayjs().format('MMM,DD,YYYY, hh:mm:ss:a'))
        })
       
    
    },[])


  return (
   <div className="container-fluid bg-primary nav-brdr">
    <div className="row">
        <div className="col">
           <div className="text-center p-2 text-white">
            {currentTime}
           </div>
        </div>
    </div>
   </div>
  )
}

export default Navbar