import React from 'react'
import {Routes,Route} from 'react-router-dom'
import  Home  from './Home'
import Registration from '../Auth/Registration'
import SigneIn from '../Auth/SigneIn'
const index = () => {
  return (
    <Routes>

    <Route path='/' element={<Home/>} />
    <Route path='registration' element={<Registration/>} />
    <Route path='signein' element={<SigneIn/>} />
</Routes>
  )
}

export default index